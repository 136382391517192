import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";

const Discounts = ({ data }) => {
  const image = getImage(data.mdx.frontmatter.hero_image);

  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
            <MDXRenderer>
              {data.mdx.body}
            </MDXRenderer>
      <p>
        <a style={{ color: "#4a71b6", textDecoration: "none" }} target="_new" rel="noreferrer" href={data.mdx.frontmatter.link}>
          Link
        </a>
      </p>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        link
      }
      body
    }
  }
`;

export default Discounts;
